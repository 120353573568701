<template>
    <div class="home container">
        <img src="@/assets/image/logo-long.png" class="logo-long">
        <img src="@/assets/image/home-banner.png" class="banner">
        <div class="nav">
            <router-link :to="{name: 'normal'}" class="menu">
                <img src="@/assets/image/nav-normal.png">
            </router-link>
<!--            <router-link :to="{name: 'visit'}" class="menu">-->
<!--                <img src="@/assets/image/nav-visit.png">-->
<!--            </router-link>-->
            <router-link :to="{name: 'guide'}" class="menu">
                <img src="@/assets/image/nav-guide.png">
            </router-link>
            <router-link :to="{name: 'appointsQuery'}" class="menu">
                <img src="@/assets/image/nav-order.png">
            </router-link>
            <router-link :to="{name: 'qa'}" class="menu">
                <img src="@/assets/image/nav-qa.png">
            </router-link>
        </div>
        <img src="@/assets/image/home-footer.png" class="footer">
    </div>
</template>
<script>
    export default {

    }
</script>
<style lang="scss">
    .home {
        padding: 0 28px;
        text-align: center;
        .banner {
            margin-top: 12px;
        }
        .nav {
            margin-top: 33px;
            .menu {
                > img {
                    width: 215px;
                }
                margin-top: 16px;
                @media (min-width: 768px) {
                    > img {
                        width: 500px;
                    }
                }
                display: block;
            }
        }
        .footer {
            margin-top: 33px;
        }
    }
</style>
